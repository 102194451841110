@import 'variables';
@import 'breakpoints';

.IntroScreen {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  z-index: 1;
  font-size: 1.25em;
  line-height: 1.3em;
  user-select: none;

  span {
    position: absolute;
    top: 45%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    padding: 0 $padding;
    box-sizing: border-box;
    font-size: 0.95em;
    user-select: none;
  }

  @include breakpoint(tablet) {
    font-size: 1.05em;
    line-height: 1.15em;

    span {
      top: 42.5%;
    }
  }
}