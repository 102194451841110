@import 'variables';
@import 'breakpoints';

.ErrorScreen {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  text-align: center;
  padding: $padding;
  background: $grey;
  color: white;
  box-sizing: border-box;
  transform: translateX(-50%);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    margin: 0;
  }

  .message {
    margin-top: -40px;
    max-width: 600px;
  }

  .learn-more {
    position: absolute;
    bottom: 1em;
  }

  a,
  .link {
    color: white;
    background-image: linear-gradient(to right, white 0%, white 100%);
    &:visited {
      color: white;
    }
  }

  @include breakpoint(tablet) {
    font-size: 30px;
  }

  @include breakpoint(iphone) {
    font-size: 26px;

    .learn-more {
      font-size: 20px;
    }
  }
}
