@import 'variables';
@import 'breakpoints';
@import 'mixins';

.Share {
  color: $red;
  pointer-events: none;
  user-select: none;
  outline: none;
  width: 100%;
  max-width: 650px;
  padding: $padding 0;
  margin: 0 auto;
  box-sizing: border-box;
  z-index: 10;
  text-align: center;
  line-height: 1.1em;

  &>div:first-of-type::before {
    content: open-quote;
  }

  &>div:first-of-type::after {
    content: close-quote;
  }

  .buttons span {
    display: inline-block;
    width: 33px;
    height: 33px;
    margin-top: $padding / 1.1;
    pointer-events: initial;

    &:first-child {
      margin-right: $margin * 1.25;
    }
  }

  @include breakpoint(tablet) {
    width: 100%;
    padding: calc(#{$padding} - 17px);
    font-size: 33px
  }
}