@import 'mixins';
@import 'variables';
@import 'breakpoints';

.WarningIcon {
  position: fixed;
  padding: $margin calc(#{$margin*2 + 5px});
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  box-sizing: border-box;
  user-select: none;
  background-color: $red;

  text-align: center;
  color: white;
  font-size: 1.7em;

  @include breakpoint(tablet) {
    font-size: 1.4em;
    top: 42.5%;
  }
}