@import 'variables';
@import 'breakpoints';

.Debug {
  position: absolute;
  left: $margin;
  bottom: $margin;
  pointer-events: none;

  pre {
    margin: 0;
    font-size: 18px;
    font-weight: bold;

    span {
      display: block;
    }
  }

  @include breakpoint(tablet) {
    left: $margin/2.0;
    bottom: $buttonSize*2;
    pointer-events: none;

    pre {
      margin: 0;
      font-size: 14px;
      font-weight: bold;

      span {
        display: block;
      }
    }
  }
}