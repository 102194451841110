@import 'variables';
@import 'breakpoints';

.Canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  outline: none;
  background-color: transparent !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .desktop & {
    cursor: none;
  }

  &::after {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('/assets/images/background.png');
    background-size: $checkerSize $checkerSize;
    image-rendering: pixelated;
    opacity: 0.1;
    overflow: hidden;
    pointer-events: var(--pointerEvents);
    // mix-blend-mode: darken;
  }
}
