@import 'variables';
@import 'breakpoints';
@import 'mixins';

.ShopLink {
  @include bottom-text();

  br {
    content: ' ';
    
    &:before {
      content: ' ';
    }
  }

  .link {
    display: inline-block;
    @include link($red);
  }

  @include breakpoint(tablet) {
    @include top-text-tablet();
  }

  @include breakpoint(from-tablet) {
    br {
      display: block;
      &:before {
        content: none;
      }
    }
  }
}
